import api from "./api";

class CourseService {
	/**************************************
	 *  API FBO
	 **************************************/
	async addCourse(params) {
		return await api
			.post("/courses/create/", params, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async updateCourse(id, params) {
		return await api
			.put("/courses/update/" + id + "/", params, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getAllCourses(params) {
		return await api
			.get("/courses/list", { params })
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async getCourse(id) {
		return await api
			.get("/courses/single/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}

	async deleteCourse(id) {
		return await api
			.delete("/courses/delete/" + id)
			.then((response) => {
				return { status: response.status, data: response.data };
			})
			.catch(function (error) {
				return { status: error.response.status, data: error.response.data };
			});
	}
}

export default new CourseService();
